import styled from '../../utils/styled'

const Container = styled('div')`
  margin: 0 auto;
  width: 100%;

  max-width: 960px;


`

export default Container
